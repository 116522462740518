<template>
    <v-select :class="align[format]"
        hide-details dense outlined
        :label="label"
        :ref="name"
        :value="formatNumber(value)"
        :items="selectOptions"
        item-text="name"
        item-value="name"
        :prefix="prefix[format]"
        :suffix="suffix[format]"
        @input="$emit('changed', $event)"
    ></v-select>
</template>
<script>
export default {
    name: 'ListSelect',
    props: ['label', 'format', 'name', 'value', 'options'],
    data: () => ({
        prefix: { 'default': '', 'number': '', 'date': '', 'decimal': '', 'euro': '', 'proz': '' },
        suffix: { 'default': '', 'number': '', 'date': '', 'decimal': '', 'euro': '€', 'proz': '%' },
        align: { 'default': '', 'number': 'right-aligned', 'date': '', 'decimal': 'right-aligned', 'euro': 'right-aligned', 'proz': 'right-aligned' },
    }),
    computed: {
        selectOptions() {
            return JSON.parse(this.options)
        },
    },
    methods: {
        formatNumber(value) {
            return parseFloat(value).toLocaleString(['de-DE', 'en'], { style: "decimal", maximumFractionDigits: 2 })
        }
    },
}
</script>
<style scoped>
.v-select__selection {
    justify-content: center;
}
</style>